
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

@font-face {
    font-family: 'GothamBook';
    src: local('GothamBook'), url('/public/assets/fonts/Gotham/GothamBook.woff') format('woff');
}

@font-face {
    font-family: 'GothamBookItalic';
    src: local('GothamBook'), url('/public/assets/fonts/Gotham/Gotham-Book-Italic.ttf') format('ttf');
}


*:not(i),
.ui,
.ui.header {
    font-family: 'Poppins','GothamBook','GothamBookItalic', sans-serif !important;
    color : #25398E;
}

h1,h2,h3,h4,h5,h6,.styletitle {
    font-family: 'Poppins','GothamBookItalic','GothamBook', sans-serif !important;
    color : #25398E;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;
}


.ui.menu .item {
    position: relative;
    vertical-align: middle;
    line-height: 1;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    flex: 0 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.92857143em 1.14285714em;
    text-transform: none;
    color : #25398E;
    font-weight: normal;
    transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
    border-radius: 0.5rem;
}

.ui.menu > .item:first-child {
    border-radius: 0.5rem;
}

.ui.menu .item.itemmenu:hover {
    background-color: #276236 ;
    background: linear-gradient(to right, #65C97A, #276236);
}

.taxonlist img {
    width: 100%;
}

a:hover {
    color: #65C97A !important;
}

.pusher > #menu::after {
    background: linear-gradient(to right, #65C97A, #276236);
}
header .ui.three.column.stackable.grid > .column:nth-child(3) .button .icon {
    color: #65C97A;
}

.ui.breadcrumb a {
    color: #65C97A;
}

.promotion_label {
    background: linear-gradient(to right, #65C97A, #276236);
    color: #25398E !important;
}

.primary.button {
    background: linear-gradient(to right, #65C97A, #276236) !important;
}

[data-route="sylius_shop_product_show"] .ui.top.attached.large.tabular.menu .item.active {
    background: linear-gradient(to right, #65C97A, #276236);
}

[data-route="sylius_shop_homepage"] .ui.huge.header::after,
.ui.horizontal.section.divider.header::after {
    color: #65C97A;
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid .column:first-child button.button {
    background: linear-gradient(to right, #65C97A, #276236);
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid .column:last-child .button {
    background: linear-gradient(to right, #65C97A, #276236);
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid .column:last-child .button:hover {
    background: #fff;
    border: 1px solid #65C97A;
}

[data-route^="sylius_shop_checkout_"] .steps .active .title,
[data-route^="sylius_shop_checkout_"] .steps .step.completed i::before {
    color: #65C97A !important;
}

.pre-footer {
    background: linear-gradient(to right, #65C97A, #276236);
}

input:focus {
    border-color: #65C97A !important;
}


.ui.button.primary,
.ui.basic.button.primary,
.ui.labeled.button.primary,
.ui.labeled.icon.button.primary,
.buttons .ui.labeled.icon.button.primary,
.ui.button.green,
.ui.basic.button.green,
.ui.labeled.button.green,
.ui.labeled.icon.button.green,
.buttons .ui.labeled.icon.button.green,
.ui.button.submit,
.ui.basic.button.submit,
.ui.labeled.button.submit,
.ui.labeled.icon.button.submit,
.buttons .ui.labeled.icon.button.submit {
    background: linear-gradient(to right, #65C97A, #276236) !important;
    color: #25398E !important;
    border: none !important;
}

.ui.button,
.ui.basic.button,
.ui.labeled.button,
.ui.labeled.icon.button,
.buttons .ui.labeled.icon.button {
    border: none !important;
}


.ui.button:hover,
.ui.basic.button:hover,
.ui.labeled.button:hover,
.ui.labeled.icon.button:hover,
.buttons .ui.labeled.icon.button:hover {
    background: linear-gradient(to right, #276236, #65C97A) !important;
    color: #25398E !important;
    border: none !important;
}

.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before,
.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
    background: linear-gradient(to right, #65C97A, #276236) !important;
}


.text-teal {
    color: #65C97A;
}


.address-card-content.default {
    background: linear-gradient(to right, #65C97A, #276236);
}

.address-label {
    background: #65C97A;
    color: #25398E;
}

[data-route="sylius_shop_homepage"] .homepage-banner .homepage-banner__content .button {
    color: #65C97A !important;
}


.ui.inverted.vertical.footer.segment {
    background: linear-gradient(to right, #5296D5, #25398E) !important;
}

.ui.card {
    margin: 0;
}


@media (min-width: 1200px) {
    [data-route=sylius_shop_product_show] .ui.two.column.stackable.grid {
        display: flex !important;
    }
    [data-route=sylius_shop_product_show] .ui.two.column.stackable.grid > .column {
        flex-basis: 20%;
    }
    [data-route=sylius_shop_product_show] .ui.two.column.stackable.grid > .column:last-child {
        flex-basis: 80%;
        padding-left: 40px !important;
    }
}
@media (min-width: 1500px) {
    [data-route=sylius_shop_product_show] .ui.two.column.stackable.grid > .column {
        flex-basis: 20%;
    }
    [data-route=sylius_shop_product_show] .ui.two.column.stackable.grid > .column:last-child {
        flex-basis: 80%;
        padding-left: 60px !important;
    }
}

.content_choix_opt .choixcouleur .boxcouleur{
    border: 3px solid #FFF; width: 50px;height: 50px;border-radius: 30%;
}


.content_choix_opt .choixcouleur.selected .boxcouleur{
    border: 3px solid red;
}

.hidden {
    display: none !important;
}

.choixReparationBox {
    padding: 5px 0 5px 0;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn, #25398E, #5296D5, #65C97A);
    border-image-slice: 1;
}

.content_choix_opt .choixReparationBox .ui.button{
    margin: 0;
    display: none;
}

.content_choix_opt .choixReparationBox .ui.button{
    color: #FFF !important;
    font-weight: bold !important;
}

.container a {
    text-decoration: none;
}

body.pushable {
    background: #fff!important;
    animation: gradientAnimation 10s ease infinite;
}

body.pushable .pusher {
    background:transparent !important;
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.ui.container {
    background: #fff;
}

#footer .ui.container {
    background: transparent;
}

[data-route="sylius_shop_homepage"] .homepage-banner .homepage-banner__image {z-index: 1;}

#logoheader {
    margin: 10px 0;
    filter: brightness(0) invert(1);
}

#menu .styletitle {
    color: #fff;
}

#menu #sylius-cart-total { color: #fff;}

#columnimgprod {
    position: relative;  /* Nécessaire pour les éléments avec position sticky */
    overflow: hidden;    /* Empêche les éléments enfants de dépasser */
}

#imgproddiv {
    position: sticky;
    top: 0;              /* L'image va rester collée en haut de la colonne */
    z-index: 1;          /* Assure-toi que l'image reste au-dessus des autres contenus */
    max-width: 100%;     /* Empêche l'image de dépasser la largeur du parent */
}

#main-image {
    width: 100%;         /* Assure que l'image ne dépasse pas */
    object-fit: contain; /* Préserve le ratio de l'image */
}
.pusher > #menu .menu .item {text-decoration: none;}

@media (max-width: 480px) {
    .header-logo {text-align: center}
}

.containerImage {
    width: 90%;
    margin: 0 5%;
    aspect-ratio: 1 / 1; /* Hauteur égale à la largeur (1:1) */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.imageProdMarque {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.contentTextProdMarque {
    text-align: center;
    font-weight: bold;
    color: #25398E;
}

.contentTextProdMarque a {
    color: #25398E;
}
